<template>
  <div class="pbz-font d-flex flex-column justify-content-center">
    <div class="d-flex">
      <h3>List Invoice</h3>
    </div>

    <div :class="$style.paymentContainer" class="d-flex flex-column justify-content-center">
      <div :class="$style.paymentContent">
        <ListInvoice />
      </div>
    </div>
  </div>
</template>

<script>
import ListInvoice from './partials/ListInvoice.vue'

export default {
  name: 'Payment',
  components: {
    ListInvoice,
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
<template>
  <a-modal
    :visible="true"
    :footer="null"
    :class="$style.modalSend"
    @cancel="() => $emit('handleSendInvoice', false)"
  >
    <a-row class="d-flex justify-content-center flex-column">
      <h4 class="text-center">
        Kirim Invoice
      </h4>
      <div class="p-1 mt-3">
        <a-col :span="12">
          Nama Customer :
        </a-col>
        <a-col :span="12">
          Toko A
        </a-col>
      </div>
      <div class="p-1">
        <a-col :span="12">
          Nama Customer :
        </a-col>
        <a-col :span="12">
          Toko A
        </a-col>
      </div>
      <div class="p-1">
        <a-col :span="12">
          Nama Customer :
        </a-col>
        <a-col :span="12">
          Toko A
        </a-col>
      </div>
      <div class="d-flex my-3 p-1">
        <div class="mr-4">
          Kirim Via
        </div>
        <a-radio>Email</a-radio>
      </div>
      <div class="d-flex justify-content-around mt-4">
        <a-button :class="$style.positiveBtn">
          Kirim
        </a-button>
        <a-button :class="$style.negativeBtn" @click="() => $emit('handleSendInvoice', false)">
          Batal
        </a-button>
      </div>
    </a-row>
  </a-modal>
</template>

<script>
export default {

}
</script>

<style lang="scss" module>
  @import '../style.module.scss';
</style>
